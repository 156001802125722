import ShortSiteLogoDark from '@assets/icons/short_wht_tranbg.svg';
import ShortSiteLogoLight from '@assets/icons/short_blk_tranbg.svg';
import {
  Box,
  Divider,
  makeStyles,
  Paper,
  Typography,
  useTheme,
  Link,
} from '@material-ui/core';
import React from 'react';
import FooterLinks from './components/footer-links';
import Subscribe from './components/Subscribe';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    paddingBottom: 0,
  },
  flexWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    '& >div': {
      margin: 'auto',
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        flexBasis: '100%',
      },
    },
  },
  sectionOne: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    '& img': {
      height: theme.spacing(9),
    },
    '&>div': {
      padding: theme.spacing(2),
      height: 'fit-content',
      margin: 0,
      display: 'flex',
      flexWrap: 'wrap',
      '& >span': {
        paddingBottom: theme.spacing(1),
        flex: '0 0 100%',
      },
    },
  },
  links: {
    flexBasis: '10%',
    '& ul': {
      display: 'flex',
      flexDirection: 'column',
      '& li': {
        padding: 0,
      },
    },
  },
  copyWrite: {
    flexBasis: '100%',
  },
}));

const Footer = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <footer className={classes.root}>
      <Divider />
      <Paper className={classes.flexWrapper} square={true} elevation={0}>
        <Box>
          <Box className={classes.sectionOne}>
            <img
              src={
                theme.palette.type === 'dark'
                  ? ShortSiteLogoDark
                  : ShortSiteLogoLight
              }
              alt="site logo"
            />
            <div>
              <span>
                <Link href="mailto:laurencarlacoaching@gmail.com">
                  laurencarlacoaching@gmail.com
                </Link>
              </span>
              <Link
                color="secondary"
                href="https://www.instagram.com/laurencarlaa"
                target="_blank"
                rel="noopener"
              >
                <span className={`icon icon--instagram`} />
              </Link>
            </div>
          </Box>
          <Subscribe />
        </Box>
        <FooterLinks />
        <Box className={classes.copyWrite}>
          <Divider />
          <Typography variant="caption">
            © {new Date().getFullYear()}, LaurenCarlaCoaching
          </Typography>
        </Box>
      </Paper>
    </footer>
  );
};

export default Footer;
