import Footer from '@features/Layout/footer';
import { CssBaseline, Paper } from '@material-ui/core';
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@material-ui/core/styles';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import '../styles/default.scss';
import Header from './Header/header';
import { connect } from 'react-redux';
import ThemeSwitch from '@features/Layout/theme-switch';

const Layout = ({ children, darkMode }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const [themeType, setThemeType] = useState('light');

  useEffect(() => {
    darkMode ? setThemeType('dark') : setThemeType('light');
  }, [darkMode]);

  const theme = createMuiTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#222222',
        light: '#494949',
        dark: '#000000',
      },
      secondary: {
        main: '#e6af2e',
        light: '#ffe161',
        dark: '#b08000',
        contrastText: '#000',
      },
      common: {
        white: '#fafafa',
        black: '#222222',
      },
      text: {
        primary: themeType === 'light' ? '#222222' : '#fafafa',
        secondary: '#e6af2e',
      },
      background: {
        paper: themeType === 'light' ? '#fff' : '#222222',
        default: '#fafafa',
      },
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
    },
    overrides: {
      MuiDivider: {
        root: {
          backgroundColor: '#e6af2e',
        },
      },
      MuiFormLabel: {
        root: {
          color: themeType === 'light' ? '#222222' : '#e6af2e',
        },
      },
    },
  });

  const responsiveTheme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={responsiveTheme}>
      <CssBaseline />
      <Paper square={true}>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
        <Footer />
        <ThemeSwitch />
      </Paper>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const mapState = state => ({ darkMode: state.view.darkMode });

export default connect(mapState, null)(Layout);
