import {
  Box,
  Button,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import React, { Component } from 'react';

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
  title: {
    textAlign: 'center',
    paddingBottom: theme.spacing(2),
  },
  flexContainer: {
    display: 'flex',
    // flexWrap: 'nowrap',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    '& .MuiTextField-root': {
      width: '25ch',
      marginTop: '8px',
      marginBottom: '4px',
      '& >.MuiOutlinedInput-root': {
        [theme.breakpoints.up('sm')]: {
          borderRadius: '4px 0 0 4px',
        },
      },
    },
    '& >.MuiButton-contained': {
      marginTop: '8px',
      marginBottom: '4px',
      [theme.breakpoints.up('sm')]: {
        borderRadius: '0 4px 4px 0',
      },
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.type === 'light' ? '#222222' : '#e6af2e',
    },
    [theme.breakpoints.down('xs')]: {
      '& :nth-child(n)': {
        flexBasis: '100%',
      },
    },
  },
});
class Subscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      EMAIL: { value: '', error: false, validationMessage: '' },
      submitted: false,
    };
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState(prevState => ({ [name]: { ...prevState, value } }));
  };

  handleOnBlur = event => {
    const target = event.target;
    const error = !target.validity.valid;
    const validationMessage = target.validationMessage;
    const name = target.name;

    this.setState(prevState => ({
      [name]: { ...prevState, error, validationMessage },
    }));
  };

  componentDidMount() {}

  render() {
    const { classes } = this.props;
    return (
      <>
        <Typography variant="caption" component="p">
          subscribe for special offers and discounts
        </Typography>
        <form
          action="https://gmail.us19.list-manage.com/subscribe/post?u=eb1d6d733600730c28b98a20b&amp;id=19913fe1b5"
          method="POST"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
        >
          <Box className={classes.flexContainer}>
            <TextField
              id="sign-up-email"
              label="Email"
              name="EMAIL"
              variant="outlined"
              margin="dense"
              value={this.state.EMAIL.value}
              onChange={this.handleInputChange}
            />
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              size="small"
              disableElevation
            >
              subscribe
            </Button>
            <div id="mce-responses" className="clear">
              <div
                className="response"
                id="mce-error-response"
                style={{ display: 'none' }}
              ></div>
              <div
                className="response"
                id="mce-success-response"
                style={{ display: 'none' }}
              ></div>
            </div>
            <div
              style={{ position: 'absolute', left: '-5000px' }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_eb1d6d733600730c28b98a20b_19913fe1b5"
                tabIndex="-1"
                value=""
                readOnly
              ></input>
            </div>
          </Box>
        </form>
      </>
    );
  }
}

export default withStyles(styles)(Subscribe);
