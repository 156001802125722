import SiteLogoDark from '@assets/icons/long_wht_tranbg.svg';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import BurgerButton from './burger-button';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  logo: {
    paddingTop: theme.spacing(1),
    width: '8rem',
    '& img': {
      width: '100%',
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'auto',
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    minHeight: 86,
  },
  drawerPaper: {
    width: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  listItem: {
    width: 'unset',
  },
  link: {
    ...theme.typography.button,
  },
}));

const scrollTo = locationId => {
  const elementPositionY = document.getElementById(locationId).offsetTop;
  window.scrollTo({
    top: elementPositionY,
    left: 0,
    behavior: 'smooth',
  });
};

const sectionList = ['services', 'personal-training'];

const Header = props => {
  const { window } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const preventDefault = event => {
    event.preventDefault();
  };

  const drawer = (
    <div id="site-header">
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {sectionList.map((item, index) => (
          <ListItem
            key={item}
            className={classes.listItem}
            button
            onClick={() => scrollTo(item)}
          >
            <ListItemText
              primary={item.replace('-', ' ')}
              primaryTypographyProps={{
                component: 'a',
                className: classes.link,
                color: 'secondary',
                href: '#',
                onClick: preventDefault,
              }}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={`flex_box flex_box__row flex_box--between`}>
          <div className={classes.logo}>
            <Link to="/">
              <img src={SiteLogoDark} alt="Lauren Carla Coaching Logo" />
            </Link>
          </div>
          <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="js">
              <Drawer
                container={container}
                variant="temporary"
                anchor={'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden smDown implementation="js">
              <List className={`flex_box flex_box__row`}>
                {sectionList.map((item, index) => (
                  <ListItem
                    key={item}
                    className={classes.listItem}
                    button
                    onClick={() => scrollTo(item)}
                  >
                    <ListItemText
                      primary={item.replace('-', ' ')}
                      primaryTypographyProps={{
                        component: 'a',
                        className: classes.link,
                        color: 'secondary',
                        href: '#',
                        onClick: preventDefault,
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Hidden>
          </nav>
          <Button color="secondary" href="mailto:laurencarlacoaching@gmail.com">
            Get&nbsp;in&nbsp;touch
          </Button>
          <BurgerButton toggleMenu={handleDrawerToggle} state={mobileOpen} />
        </Toolbar>
      </AppBar>
      <Toolbar className={classes.toolbar} />
    </div>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
