import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    background: 'transparent',
    border: 'none',
    margin: 0,
    // marginRight: theme.spacing(2),
    '&.active div': {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      transition: 'ease-in 0.1s',
      '&:before': {
        top: 0,
        transform: 'rotate(-45deg)',
        transition:
          'top 0.1s linear, transform 225ms cubic-bezier(0, 0, 0.2, 1) 100ms',
      },
      '&:after': {
        bottom: 0,
        transform: 'rotate(45deg)',
        transition:
          'bottom 0.1s linear, transform 225ms cubic-bezier(0, 0, 0.2, 1) 100ms',
      },
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  container: {
    width: '2.5em',
    height: '2.5em',
    position: 'relative',
  },
  inner: {
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    width: '2.5em',
    height: '0.35em',
    top: '42%',
    transition: 'border-color 0s linear',
    '&:before': {
      backgroundColor: theme.palette.common.white,
      height: '0.35em',
      content: '""',
      top: '-12px',
      left: 0,
      position: 'absolute',
      width: '2.5em',
      transition:
        'top 0.5s ease-in-out, transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    },
    '&:after': {
      backgroundColor: theme.palette.common.white,
      height: '0.35em',
      content: '""',
      bottom: '-12px',
      left: 0,
      position: 'absolute',
      width: '2.5em',
      transition:
        'bottom 0.5s ease-in-out, transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    },
  },
}));

const BurgerButton = ({ toggleMenu, state }) => {
  const [active, setActive] = useState();

  function handleButtonClick() {
    toggleMenu();
  }

  useEffect(() => {
    setActive(state);
  }, [state]);

  const classes = useStyles();

  return (
    <button
      className={`${classes.root} ${active ? 'active' : ''}`}
      onClick={handleButtonClick}
      aria-label="open drawer"
    >
      <div className={classes.container}>
        <div className={classes.inner}></div>
      </div>
    </button>
  );
};

export default BurgerButton;
