import { Box, IconButton, makeStyles } from '@material-ui/core';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import { toggleTheme } from '@store/actions';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    bottom: 0,
    height: 48,
    marginLeft: 'auto',
    right: 0,
    position: 'sticky',
    width: 48,
    zIndex: 999,
  },
  corner: {
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      border: '48px solid transparent',
      borderBottomColor: '#333',
      borderTop: 0,
      borderRight: 0,
      marginTop: 0,
      marginRight: 0,
    },
  },
  button: {
    height: 'fit-content',
    padding: 0,
    position: 'absolute',
    right: 2,
    top: 22,
  },
}));

const ThemeSwitch = ({ darkMode, toggleTheme }) => {
  const classes = useStyles();

  const handleButtonClick = () => {
    toggleTheme(!darkMode);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.corner}>
        <IconButton
          className={classes.button}
          color="secondary"
          size="medium"
          onClick={handleButtonClick}
        >
          <Brightness4Icon fontSize="inherit" />
        </IconButton>
      </Box>
    </Box>
  );
};

ThemeSwitch.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  toggleTheme: PropTypes.func.isRequired,
};

const mapState = state => ({ darkMode: state.view.darkMode });
const mapDispatchToProps = dispatch => {
  return { toggleTheme: darkMode => dispatch(toggleTheme(darkMode)) };
};
export default connect(mapState, mapDispatchToProps)(ThemeSwitch);
