import {
  Box,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    flexBasis: '10%',
    '& ul': {
      display: 'flex',
      flexDirection: 'column',
      '& li': {
        padding: 0,
      },
    },
  },
  listItem: {
    marginBottom: '2px',
  },
  link: {
    ...theme.typography.button,
  },
}));

const scrollTo = locationId => {
  const elementPositionY = document.getElementById(locationId).offsetTop;
  window.scrollTo({
    top: elementPositionY,
    left: 0,
    behavior: 'smooth',
  });
};

const FooterLinks = () => {
  const classes = useStyles();
  const preventDefault = event => {
    event.preventDefault();
  };

  return (
    <Box className={classes.root}>
      <section>
        <Typography variant="h6" component="h1">
          Navigate
        </Typography>
        <List className={`flex_box flex_box__row`}>
          {['services', 'personal-training'].map((item, index) => (
            <ListItem
              key={item}
              className={classes.listItem}
              button={false}
              dense={true}
              onClick={() => scrollTo(item)}
            >
              <ListItemText
                primary={item.replace('-', ' ').toUpperCase()}
                primaryTypographyProps={{
                  component: 'a',
                  color: 'secondary',
                  className: classes.link,
                  href: '#',
                  onClick: preventDefault,
                }}
              />
            </ListItem>
          ))}
        </List>
      </section>
    </Box>
  );
};

export default FooterLinks;
